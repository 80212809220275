<template>
  <!-- 系统设置 - 资讯公告-详情 -->
  <div class="pageContol aeAdvisoryNotice">
    <div class="framePage">
      <!-- 面包屑 -->
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">运营工具</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">优秀企业推荐</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">优秀企业推荐-详情</a>
        </span>
      </div>
      <!-- 编辑内容 -->
      <div class="framePage-body">
        <el-form ref="form" :rules="rules" :model="pageData" label-width="120px">
          <el-form-item label="企业名称" prop="compId">
            <el-select size="small" v-model="pageData.compId" remote :remote-method="getCompanyList" filterable clearable
              placeholder="请至少输入两个字搜索" class="width_w" :disabled="lastPageData.recommendId ? true : false">
              <el-option v-for="item in CompanyList" :key="item.compId" :label="item.compName"
                :value="item.compId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="企业logo" prop="compLogo" required>
            <el-upload :on-change="handleAvatarSuccess1" :before-upload="beforeAvatarUpload1" :http-request="$requestMine"
              :show-file-list="false" class="img-el-upload" action accept="image/png, image/gif, image/jpg, image/jpeg">
              <el-image :src="pageData.compLogoUrl || require('@/assets/develop.png')" fit="contain"
                class="imgCenter"></el-image>
            </el-upload>
          </el-form-item>
          <el-form-item label="企业展示" prop="compShow" required>
            <el-upload :on-change="handleAvatarSuccess2" :before-upload="beforeAvatarUpload2" :http-request="$requestMine"
              :show-file-list="false" class="img-el-upload" action accept="image/png, image/gif, image/jpg, image/jpeg">
              <el-image :src="pageData.compShowUrl || require('@/assets/develop.png')" fit="contain"
                class="imgCenter"></el-image>
            </el-upload>
          </el-form-item>
          <el-form-item label="联系人" prop="contact">
            <el-input size="small" placeholder="请输入联系人" v-model="pageData.contact" class="width_w"></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="contactNumber">
            <el-input size="small" placeholder="请输入联系电话" v-model="pageData.contactNumber" class="width_w"></el-input>
          </el-form-item>
          <el-form-item label="企业简介" prop="intro">
            <div ref="editor" class="editor" style="width:100%;max-width:800px" />
          </el-form-item>
          <el-form-item label="推荐指数" prop="recommendIndex" class="tjzs">
            <el-rate v-model="pageData.recommendIndex"></el-rate>
          </el-form-item>
          <el-form-item>
            <el-button class="bgc-bv" @click="cancel">取消</el-button>
            <el-button type="primary" class="bgc-bv" @click="addEdit('form')">确定</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import E from "wangeditor";
import UploadPic from "@/mixins/UploadPic";
let editor = {};
var checkAge = (rule, value, callback) => {
  if (value < 1) {
    return callback(new Error('请选择推荐指数'));
  } else {
    callback();
  }
};
export default {
  name: "workOrder_excellentEnterpriserEcommendInfo",
  mixins: [UploadPic],
  data() {
    return {
      // 上个页面传过来的数据
      lastPageData: {
        recommendId: "", // 机构id
      },
      // 页面数据
      pageData: {
        compId: "", // 企业id
        compLogo: "", // 企业logo
        compLogoUrl: "", // 企业logo
        compShow: "", // 企业展示
        compShowUrl: "", // 企业展示
        contact: "", // 联系人
        contactNumber: "", // 联系电话
        intro: "", // 企业简介
        recommendIndex: null, // 推荐指数
      },
      // 企业名称 - 下拉数据
      CompanyList: [],
      // 校验输入
      rules: {
        compId: [{ required: true, message: "请选择企业", trigger: "change" }],
        compLogo: [
          { required: true, message: "请上传企业logo", trigger: "change" }
        ],
        compShow: [
          { required: true, message: "请上传企业展示", trigger: "change" }
        ],
        contact: [
          { required: true, message: "请输入联系人", trigger: "blur" }
        ],
        contactNumber: [
          {
            required: true,
            validator: this.$validatePhone,
            trigger: "blur",
          },
        ],
        intro: [
          { required: true, message: "请输入企业简介", trigger: "blur" },
        ],
        recommendIndex: [
          { required: true, validator: checkAge, trigger: "change" }
        ],
      }
    };
  },
  mounted() {
    this.editorInit();
  },
  computed: {},
  created() {
    // 初始化 - 资讯列表传过来的id
    this.lastPageData.recommendId = this.$route?.query?.recommendId ?? "";
    if (this.lastPageData.recommendId) {
      this.getInfoData();
    }
  },
  methods: {
    // 获取 - 详情
    getInfoData() {
      this.$post("/biz/company/recommend/getInfo", { recommendId: this.lastPageData.recommendId })
        .then((res) => {
          this.pageData = { ...res?.data ?? {} }
          editor.txt.html(this.pageData?.intro ?? "");
          this.getCompany(res.data.compId);
        })
        .catch(() => {
          return;
        });
    },
    // 获取 - 企业名称
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    // 获取 - 根据企业id回显当前id的数据
    getCompany(id) {
      this.$post("/sys/company/id", { compId: id }).then((res) => {
        this.CompanyList = [res.data];
        this.$set(this.pageData, "compId", this.CompanyList[0].compId);
      });
    },
    // 企业logo - 图片上传
    handleAvatarSuccess1(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      formData.append("isPublic ", true);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then(result => {
            this.pageData.compLogoUrl = result.data.fileURL || "";
            this.pageData.compLogo = result.data.fileKey || "";
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败"
              });
            }, 300);
          });
      }
    },
    // 企业展示 - 图片上传
    handleAvatarSuccess2(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      formData.append("isPublic ", true);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then(result => {
            this.pageData.compShowUrl = result.data.fileURL || "";
            this.pageData.compShow = result.data.fileKey || "";
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败"
              });
            }, 300);
          });
      }
    },
    // 企业简介 - 富文本
    editorInit() {
      editor = new E(this.$refs.editor);
      editor.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        "image", // 插入图片
        "table", // 表格
        "undo", // 撤销
        "redo" // 重复
      ];
      editor.config.zIndex = 100;
      editor.config.uploadImgShowBase64 = true;
      editor.config.uploadImgMaxSize = 5 * 1024 * 1024;
      editor.config.pasteFilterStyle = "text";
      editor.config.pasteIgnoreImg = false; //富文本编辑器中点击图片上传选择图片后激发的事件：
      let _this = this;
      editor.config.customUploadImg = function (files, insert) {
        //对上传的图片进行处理，图片上传的方式
        for (let i = 0; i < files.length; i++) {
          let formData = new FormData();
          formData.append("folder ", "COURSE_WARE");
          formData.append(
            "fileType ",
            files[i].name.substring(files[i].name.lastIndexOf(".") + 1)
          );
          formData.append("isPublic ", true);
          formData.append("file", files[i]);
          _this.$Postformat("/sys/upload", formData).then(result => {
            insert(result.data.fileURL);
          });
        }
      };
      editor.config.onchange = html => {
        this.pageData.intro = html;
      };
      editor.config.pasteTextHandle = function (content) {
        return content + "<p></p>";
        // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
      };
      editor.create();
      const eWidth = this.$refs.editor.clientWidth;
      this.eWidth = eWidth;
    },
    // 取消
    cancel() {
      this.$router.back();
    },
    // 添加/编辑
    addEdit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let url = "";
          let data = {
            ...this.pageData
          }
          if (this.lastPageData.recommendId) {
            url = "/biz/company/recommend/modify";
            data.recommendId = this.lastPageData.recommendId;
          } else {
            url = "/biz/company/recommend/insert";
          }
          this.$post(url, data).then(res => {
            this.$message.success('操作成功');
            this.$router.push({
              path: "/web/workOrder/excellentEnterpriserEcommend",
              query:{
                type: this.lastPageData.recommendId? 2 : 1
              }
            });
          });
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.width_w {
  width: 284px;
}

.tjzs {
  display: flex;
  align-items: center;

  /deep/ .el-form-item__content {
    margin-left: 0 !important;
  }
}
</style>
